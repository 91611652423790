<template>
    <main class="about">
        <div class="about-main__body">
            <div class="about-main__content">
                <h1 class="about-main__title">Acerca de Jdescuentos Chile</h1>

                <img
                    class="about-main__img"
                    :src="$assets.illustration.aboutUs.jdescuentos.bannerAboutUs1"
                    alt="Jdescuentos banner"
                />

                <p class="about-main__text">
                    <NuxtLink class="about-main__link" to="/">Jdescuentos</NuxtLink> Chile es el sitio número
                    1 de cupones y ofertas en Chile. Es una comunidad en línea que reúne descuentos en todas
                    las categorías. De esta manera, nuestros usuarios pueden planificar sus compras
                    garantizando siempre la mejor relación calidad precio. Anteriormente PromoCupón, ahora
                    hemos migrado a una comunidad fresca, viva, dinámica y actualizada cada minuto.
                </p>

                <h2 class="about-main__subtitle">Nuestra historia</h2>

                <p class="about-main__text">
                    En el 2021, iniciamos como un sitio web que compartía cupones y ofertas en línea de manera
                    gratuita para todos los usuarios. Al poco tiempo, nuestra plataforma se hizo popular entre
                    los chilenos que buscan ahorrar dinero, desde pedidos de comida a domicilio, hasta compras
                    en moda, tecnología, hogar y más.
                </p>

                <p class="about-main__text">
                    Es por esta razón que en 2024 desarrollamos una plataforma que se convertirá en la más
                    grande comunidad de ahorradores en Chile. Un sitio web que permite a sus usuarios
                    compartir sus propias ofertas y cupones, descubrir ofertas y cupones compartidos por otros
                    usuarios, comentar y calificar ofertas y, lo más importante, conectar con otras personas
                    que buscan ahorrar dinero.
                </p>

                <h2 class="about-main__subtitle">Nuestro propósito</h2>

                <p class="about-main__text">
                    En definitiva, nuestro propósito es redefinir el ahorro para los chilenos, hacer de cada
                    compra una experiencia gratificante en la que nuestros usuarios siempre reciban el mayor
                    beneficio posible sin gastar demás. Al mismo tiempo, nos impulsa la transparencia, la
                    colaboración y el consumo responsable, valores que están integrados en cada una de
                    nuestras actividades.
                </p>

                <h2 class="about-main__subtitle">¿Cómo funciona Jdescuentos?</h2>

                <p class="about-main__text">
                    Todos los usuarios registrados en Jdescuentos pueden compartir ofertas y cupones que han
                    encontrado en línea. De esta manera, el resto de los usuarios podrán navegar entre las
                    diferentes categorías o tiendas para encontrar descuentos de su interés. Lo mejor de todo
                    es que cada oferta y cupón se puede calificar y comentar. En este sentido, es nuestra
                    comunidad quien determina la calidad de cada descuento.
                </p>

                <p class="about-main__text">
                    Ahora bien, cada persona podrá crear un perfil con el fin de guardar ofertas o cupones que
                    quisiera ver más tarde. Al mismo tiempo, podrá interactuar con otros usuarios a través de
                    comentarios, foros o, incluso, mensajes privados. En definitiva, Jdescuentos es un espacio
                    donde todos nos ayudamos a ahorrar y a comprar de manera inteligente.
                </p>

                <img
                    class="about-main__img"
                    :src="$assets.illustration.aboutUs.jdescuentos.bannerAboutUs2"
                    alt="Jdescuentos banner"
                />

                <h2 class="about-main__subtitle">¿Cómo ganamos dinero?</h2>

                <p class="about-main__text">
                    Jdescuentos es un sitio web gratuito, de hecho, puedes aprovechar tantos cupones y ofertas
                    como quieras. Ahora bien, con el fin de mejorar tu experiencia, en nuestra plataforma no
                    encontrarás anuncios de ningún tipo, por lo tanto, principalmente nuestro método de
                    monetización se centra en las redes de afiliados.
                </p>

                <p class="about-main__text">
                    En este sentido, algunos cupones y ofertas están publicados con un enlace de afiliado, de
                    modo que en caso de concretarse la compra, nuestro sitio recibe una pequeña comisión por
                    ello. Sin embargo, este porcentaje no está relacionado con el costo final de la compra.
                </p>

                <h2 class="about-main__subtitle">Nuestro equipo</h2>

                <p class="about-main__text">
                    <a class="about-main__link" href="https://www.hurtadodeals.com/" target="_blank"
                        >Hurtado Deals</a
                    >
                    es la empresa que dirige a Jdescuentos, fundada en el 2013, se ha especializado en el
                    campo de los cupones y descuentos en línea, creando plataformas para comunidades en
                    México, España y, ahora, en Chile. La clave de su éxito es la colaboración con redes de
                    afiliados, lo cual permite conseguir cupones exclusivos, siendo este el principal aporte
                    para nuestra comunidad.
                </p>

                <p class="about-main__text">Ahora bien, nuestro equipo está conformado principalmente por:</p>

                <section class="about-main__team">
                    <article class="team-card">
                        <div class="team-card__info">
                            <img
                                class="info-img"
                                :src="$assets.illustration.aboutUs.jdescuentos.aboutPeople1"
                                alt="Jdescuentos banner"
                            />

                            <div class="info-content">
                                <h3 class="info-content__name">Juanca Hurtado</h3>
                                <h3 class="info-content__job">CEO</h3>
                            </div>
                        </div>

                        <p class="team-card__text">
                            Juanca es el encargado de nuestras estrategias de crecimiento y expansión en el
                            mercado chileno, su colaboración con socios comerciales y redes de afiliados ha
                            sido clave para permitir que nuestros usuarios encuentren descuentos exclusivos.
                            Al mismo tiempo, su trayectoria en el desarrollo de sitios de cupones y ofertas le
                            ha permitido mantener un enfoque innovador al momento de ofrecer la mejor
                            experiencia de usuario en nuestra web.
                        </p>
                    </article>

                    <hr class="about-main__team-line" />

                    <article class="team-card">
                        <div class="team-card__info">
                            <img
                                class="info-img"
                                :src="$assets.illustration.aboutUs.jdescuentos.aboutPeople2"
                                alt="Jdescuentos banner"
                            />

                            <div class="info-content">
                                <h3 class="info-content__name">Mairlyn Guía</h3>
                                <h3 class="info-content__job">Editora de contenidos</h3>
                            </div>
                        </div>

                        <p class="team-card__text">
                            Mairlyn es la encargada de verificar los cupones y ofertas que diariamente se
                            publican en Jdescuentos. Al mismo tiempo, colabora con la creación de contenido
                            útil y relevante para nuestros usuarios, desde guías de ahorro hasta términos y
                            condiciones de una promoción, su trabajo nos permite ofrecer contenido de calidad
                            en cada una de nuestras publicaciones.
                        </p>
                    </article>
                </section>
            </div>
        </div>
    </main>
</template>

<script lang="ts" setup>
const Route = useRoute()

const {
    public: { origin },
} = useRuntimeConfig()

const description =
    'Aprende más sobre Jdescuentos y quién está detrás. Conoce quiénes somos, nuestras actividades y por qué elegirnos.'
const title = 'Quiénes somos | Jdescuentos'

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})
</script>

<style lang="postcss">
.about {
    &-main {
        @apply m-0 mx-auto space-x-2 lg:container md:mb-10 md:mt-4 lg:max-w-screen-xl;
        &__body {
            @apply mx-auto my-4 max-w-screen-xl overflow-hidden bg-white lg:container md:rounded-3xl lg:w-3/4;
        }
        &__content {
            @apply px-6 py-4;
        }

        &__title {
            @apply mb-6 mt-4 text-center text-2xl font-semibold;
        }

        &__subtitle {
            @apply mb-4 text-2xl font-semibold;
        }

        &__img {
            @apply mb-6 block w-full object-contain md:mb-8 md:h-[500px];
        }

        &__text {
            @apply mb-6 text-base;
        }

        &__link {
            @apply font-semibold underline;
        }

        &__team {
            @apply mt-8 space-y-8;

            &-line {
                @apply w-full bg-gray-200;
            }

            .team-card {
                @apply flex flex-col gap-2 last:mb-4;

                &__info {
                    @apply flex gap-4;

                    .info-img {
                        @apply h-32 w-32 rounded-3xl object-contain;
                    }

                    .info-content {
                        @apply flex flex-col justify-center;

                        &__name {
                            @apply text-lg font-semibold;
                        }

                        &__job {
                            @apply text-base;
                        }

                        &__nav {
                            @apply flex items-center space-x-3;

                            a {
                                @apply underline;
                            }
                        }
                    }
                }

                &__text {
                    @apply col-span-5 row-start-4 text-base;
                }
            }
        }
    }
}
</style>
